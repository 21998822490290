<template>
  <el-dialog :title="title"
             :close-on-click-modal="false"
             :visible.sync="isOpenModal"
             append-to-body
             width="600px" @open="openModal"
             @closed="closeModal">
    <ch-form
        :render-option="option"
        :model="modalForm"
        ref="form"
        :rules="rules"
        :props="{ labelWidth: '120px', paddingRight: '0px' }"
    ></ch-form>

    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok" :loading="isSubmitLoading" @click="handleModal">确定</ch-button>
    </div>
  </el-dialog>
</template>
<script>
import {phone} from '@/utils/validator'
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data() {
    return {
      isOpenModal: false,
      isSubmitLoading: false,
      type: false,
      title: '',
      modalForm: {},
      rules:{
        name: [{ required: true, message: '请输入名称', trigger: ['blur', 'change'] }],
        phone: [{ required: true, pattern: phone, message: '请输入正确的手机号', trigger: ['blur', 'change'] }],
        commissionRate: [{ required: true, message: '请输入服务分成比例', trigger: ['blur', 'change'] }],
        appreciationProportion: [{ required: true, message: '请输入增值分成比例', trigger: ['blur', 'change'] }],
      }
    }
  },
  computed: {
    option() {
      return [
        {type: 'input', label: '姓名', prop: 'name', placeholder: '请输入名称'},
        {type: 'input', label: '手机号', prop: 'phone', placeholder: '请输入手机号'},
        {type: 'number', label: '服务分成比例', prop: 'commissionRate', placeholder: '请输入服务分成比例',sign:'%',precision:'2'},
        {type: 'number', label: '增值分成比例', prop: 'appreciationProportion', placeholder: '请输入增值分成比例',sign:'%',precision:'2'},
      ]
    }
  },
  methods:{
    openModal(){
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    openAdd(){
      this.isOpenModal = true
      this.type = 'add'
      this.title = '新增临时服务人员'
    },
    openEdit(row){
      this.isOpenModal = true
      this.type = 'edit'
      this.title = '编辑临时服务人员'
      this.modalForm = JSON.parse(JSON.stringify(row))
    },
    handleModal(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.isSubmitLoading = true
          if(this.type === 'add'){
            this.$curl.post('/hm/serviceStaff/addTemporary', this.modalForm).then(() => {
              this.$message.success('新增成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }else{
            this.$curl.post('/hm/serviceStaff/editTemporary', this.modalForm).then(() => {
              this.$message.success('编辑成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }

        }
      })
    },
    closeModal(){
      this.modalForm = {}
      this.title = ''
      this.isOpenModal = false
      this.isSubmitLoading = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>